import PropTypes from "prop-types";
import { memo } from "react";
import TripAdvisorRatingImage from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorRatingImage/TripAdvisorRatingImage";
import "./TripAdvisorSubratings.scss";

const TripAdvisorSubratings = ({ subratings = [] }) => {
	if (subratings?.length === 0) {
		return false;
	}

	return (
		<div className="tripadvisor-subratings" data-testid="tripadvisor-subratings">
			{subratings.map(({ localized_name, value }, index) => {
				return (
					<div className="tripadvisor-subratings__subrating" key={index}>
						<TripAdvisorRatingImage rating={value} />
						<div className="tripadvisor-subratings__name">{localized_name}</div>
					</div>
				);
			})}
		</div>
	);
};

TripAdvisorSubratings.propTypes = {
	subratings: PropTypes.array,
};

export default memo(TripAdvisorSubratings);
